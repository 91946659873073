import React from 'react';
import './layout.css';
import Helmet from 'react-helmet';

import thumbnailImg from '../assets/landing.png'

export default ({ children }) => (
  <div>
    <Helmet>
      <title>WearDuke</title>
      <html lang="en" />
      <meta charset="UTF-8" />
      <meta name="description" content="Information on WearDuke study" />
      <meta property="og:title" content="WearDuke" />
      <meta property="og:image" content={thumbnailImg} />
      <meta property="og:description" content="WearDuke is a new campus health initiative, promoting wellness through the use of digital technology. The wearable device market has exploded in recent years. Millions of people are tracking their steps, heart rate, sleep and more to make informed decisions about their health. We provide wearables to incoming students to help them monitor their rest and activity and aim to create new support channels based on these trends. In particular, wearables allow students to track their sleep, record their activity, and visualize their progress. We'd like to thank those who make this initiative possible. With their support, we are able to continue working toward our vision of student health." />
      <meta property="og:url" content="//wearduke.duke.edu/" />
      <meta
        name="keywords"
        content="WearDuke, initiative, Duke, study, wearables"
      />
      <link
        rel="stylesheet"
        href="https://alertbar.oit.duke.edu/sites/all/themes/blackwell/css/alert.css"
      />
    </Helmet>
    {children}
  </div>
);
