import React from 'react';

import Layout from '../components/layout';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

export default () => (
  <Layout>
    <NotFoundPage />
  </Layout>
);
